import { filterMap } from "../shared/functions/filter-map";

export type APPLICATION_STEP =
  | "TERMS_CONDITIONS"
  | "INTEGRATIONS"
  | "APPLICATION_DETAILS"
  | "YOUR_DETAILS"
  | "DEPENDANTS"
  | "ADDRESS_HISTORY"
  | "EMPLOYMENT_INCOME"
  | "MONTHLY_OUTGOINGS"
  | "CREDIT_LIABILITIES"
  | "ASSETS"
  | "PROPERTY_DETAILS"
  | "PAYMENT_DETAILS"
  // | "INTEREST_ONLY"
  | "DOCUMENTS";

export const APPLICATION_STEP_NAME = new Map<APPLICATION_STEP, string>([
  ["TERMS_CONDITIONS", "Terms and Conditions"],
  ["INTEGRATIONS", "Integrations"],
  ["APPLICATION_DETAILS", "Application Details"],
  ["YOUR_DETAILS", "Personal Details"],
  ["DEPENDANTS", "Dependants"],
  ["ADDRESS_HISTORY", "Address History"],
  ["EMPLOYMENT_INCOME", "Income"],
  ["MONTHLY_OUTGOINGS", "Monthly Outgoings"],
  ["CREDIT_LIABILITIES", "Credit Liabilities"],
  ["ASSETS", "Assets"],
  ["PROPERTY_DETAILS", "Property To Be Mortgaged"],
  ["PAYMENT_DETAILS", "Payment and Preferences"],
  // ["INTEREST_ONLY", "Interest Only"],
  ["DOCUMENTS", "Upload Documents"],
]);

/** Used as step page title */
export const APPLICATION_STEP_TITLE = new Map<APPLICATION_STEP, string>([
  ["MONTHLY_OUTGOINGS", "Current Monthly Outgoings"],
]);

export const NOT_FOLLOW_APPLICATION_STEPS: APPLICATION_STEP[] = [
  "TERMS_CONDITIONS",
  "INTEGRATIONS",
];

export const COLLABORATIVE_APPLICATION_STEPS: APPLICATION_STEP[] = [
  "DEPENDANTS",
  "ASSETS",
];

export const COMMON_APPLICATION_STEPS: APPLICATION_STEP[] = [
  "APPLICATION_DETAILS",
  "DEPENDANTS",
  "ASSETS",
  "PAYMENT_DETAILS",
  "PROPERTY_DETAILS",
];

export type APPLICATION_STEP_STATUS =
  | "NOT_STARTED"
  | "IN_PROGRESS"
  | "RETURNED"
  | "DONE";

// is it used?
// export const APPLICATION_STEP_STATUS_NAME = new Map<APPLICATION_STEP_STATUS, string>([
//   ["NOT_STARTED", "NOT_STARTED"],
//   ["IN_PROGRESS", "IN_PROGRESS"],
//   ["DONE", "DONE"]
// ])

export type APPLICANT_CATEGORY =
  | "RESIDENTIAL"
  | "BUY_TO_LET"
  | "RESIDENTIAL_REMORTGAGE"
  | "BUY_TO_LET_REMORTGAGE"
  | "LET_TO_BUY";

export const APPLICANT_CATEGORY_NAMES = new Map<APPLICANT_CATEGORY, string>([
  ["RESIDENTIAL", "Residential Purchase"],
  ["BUY_TO_LET", "Buy To Let Purchase"],
  ["RESIDENTIAL_REMORTGAGE", "Residential Remortgage"],
  ["BUY_TO_LET_REMORTGAGE", "Buy To Let Remortgage"],
  ["LET_TO_BUY", "Let To Buy"],
]);

export type APPLICANT_SCHEME =
  | "RIGHT_TO_BUY"
  | "BUY_TO_LET"
  | "SHARED_OWNERSHIP"
  | "HELP_TO_BUY"
  | "DISCOUNT_MARKET_SALE"
  | "CONCESSIONARY_PURCHASE"
  | "NONE";

export const APPLICANT_SCHEME_NAMES_ALL = new Map<APPLICANT_SCHEME, string>([
  ["RIGHT_TO_BUY", "Right to Buy"],
  ["BUY_TO_LET", "Buy to Let Purchase"],
  ["SHARED_OWNERSHIP", "Shared Ownership"],
  ["HELP_TO_BUY", "Help to Buy"],
  ["DISCOUNT_MARKET_SALE", "Discount Market Sale"],
  ["CONCESSIONARY_PURCHASE", "Concessionary Purchase"],
  ["NONE", "None"],
]);

export const APPLICANT_SCHEME_NAMES: Map<APPLICANT_SCHEME, string> = filterMap(
  APPLICANT_SCHEME_NAMES_ALL,
  ([k, v]) => k !== "BUY_TO_LET"
);

export type APPLICATION_TYPE = "SOLO" | "JOINED";

export const APPLICATION_TYPE_NAME = new Map<APPLICATION_TYPE, string>([
  ["SOLO", "Solo Application"],
  ["JOINED", "Joint Application"],
]);

export type APPLICANT_RELATIONSHIP =
  | "CHILD"
  | "SPOUSE"
  | "PARTNER"
  | "OTHER_RELATIVE"
  | "OTHER";

export const APPLICANT_RELATIONSHIP_NAME = new Map<
  APPLICANT_RELATIONSHIP,
  string
>([
  ["CHILD", "Child"],
  ["SPOUSE", "Spouse"],
  ["PARTNER", "Partner"],
  ["OTHER_RELATIVE", "Other Relative"],
  ["OTHER", "Other"],
]);

export type PERSONAL_TITLE =
  | "MR"
  | "MRS"
  | "MISS"
  | "MS"
  | "MX"
  | "DR"
  | "SIR"
  | "LORD"
  | "LADY";

export const PERSONAL_TITLE_NAME = new Map<PERSONAL_TITLE, string>([
  ["MR", "Mr"],
  ["MRS", "Mrs"],
  ["MISS", "Miss"],
  ["MS", "Ms"],
  ["MX", "Mx"],
  ["DR", "Dr"],
  ["SIR", "Sir"],
  ["LORD", "Lord"],
  ["LADY", "Lady"],
]);

export type GENDER = "MALE" | "FEMALE" | "OTHER";

export const GENDER_NAME = new Map<GENDER, string>([
  ["MALE", "Male"],
  ["FEMALE", "Female"],
  ["OTHER", "Other"],
]);

export type GENDER_BIRTH = "MALE" | "FEMALE";

export const GENDER_BIRTH_NAME = new Map<GENDER_BIRTH, string>([
  ["MALE", "Male"],
  ["FEMALE", "Female"],
]);

export type PUSH_TO_MIDAS_TYPE = "STANDARD" | "FULL";

export const PUSH_TO_MIDAS_TYPE = new Map<PUSH_TO_MIDAS_TYPE, string>([
  ["STANDARD", "Standard"],
  ["FULL", "Full"],
]);

export type NAME_CHANGE_REASON = "MARRIAGE" | "OTHER";

export const NAME_CHANGE_REASON_NAMES = new Map<NAME_CHANGE_REASON, string>([
  ["MARRIAGE", "Marriage"],
  ["OTHER", "Other"],
]);

export type MARTITAL_STATUS = "MARRIED" | "SINGLE" | "WIDOWED" | "DIVORCED";

export const MARTITAL_STATUS_NAME = new Map<MARTITAL_STATUS, string>([
  ["MARRIED", "Married"],
  ["SINGLE", "Single"],
  ["WIDOWED", "Widowed"],
  ["DIVORCED", "Divorced"],
]);

export type VISA_STATUS =
  | "TIER_1"
  | "TIER_2"
  | "TIER_3"
  | "TIER_4"
  | "TIER_5"
  | "INDEFINITE"
  | "FAMILY_SPOUSAL";

export const VISA_STATUS_NAMES = new Map<VISA_STATUS, string>([
  ["TIER_1", "Tier 1"],
  ["TIER_2", "Tier 2"],
  ["TIER_3", "Tier 3"],
  ["TIER_4", "Tier 4"],
  ["TIER_5", "Tier 5"],
  ["INDEFINITE", "Indefinite"],
  ["FAMILY_SPOUSAL", "Family / Spousal"],
]);

export const MONTHS = new Map<number, string>([
  [1, "January"],
  [2, "February"],
  [3, "March"],
  [4, "April"],
  [5, "May"],
  [6, "June"],
  [7, "July"],
  [8, "August"],
  [9, "September"],
  [10, "October"],
  [11, "November"],
  [12, "December"],
]);

export type DEPENDANT_TYPE = "CHILD" | "SPOUSE" | "ELDERLY" | "OTHER";

export const DEPENDANT_TYPE_NAME = new Map<DEPENDANT_TYPE, string>([
  ["CHILD", "Child"],
  ["SPOUSE", "Spouse"],
  ["ELDERLY", "Elderly"],
  ["OTHER", "Other"],
]);

export type DEPENDANT_GENDER = "MALE" | "FEMALE" | "OTHER";

export const DEPENDANT_GENDER_NAME = new Map<DEPENDANT_GENDER, string>([
  ["MALE", "Male"],
  ["FEMALE", "Female"],
  ["OTHER", "Other"],
]);

export type RESIDENTIAL_STATUS = "FAMILY" | "OWNER" | "RENTER" | "OTHER";

export const RESIDENTIAL_STATUS_NAME = new Map<RESIDENTIAL_STATUS, string>([
  ["FAMILY", "Family"],
  ["OWNER", "Owner"],
  ["RENTER", "Renter"],
  ["OTHER", "Other"],
]);

export type EMPLOYMENT_STATUS =
  | "EMPLOYED"
  | "SELF_EMPLOYED"
  | "CONTRACTOR"
  | "UNEMPLOYED"
  | "HOUSE_PERSON"
  | "RETIRED";

export const EMPLOYMENT_STATUS_NAME = new Map<EMPLOYMENT_STATUS, string>([
  ["EMPLOYED", "Employed"],
  ["SELF_EMPLOYED", "Self Employed"],
  ["CONTRACTOR", "Contractor"],
  ["UNEMPLOYED", "Unemployed"],
  ["HOUSE_PERSON", "Houseperson"],
  ["RETIRED", "Retired"],
]);

export type INCOME_TYPE =
  | "BENEFIT"
  | "MAINTENANCE"
  | "PENSION"
  | "STATE_PENSION"
  | "PRIVATE_PENSION"
  | "FOSTER_CARE"
  | "RENTAL"
  | "BURSARY"
  | "TRUST"
  | "OTHER";

export const INCOME_TYPE_NAME = new Map<INCOME_TYPE, string>([
  ["BENEFIT", "Benefit"],
  ["MAINTENANCE", "Maintenance"],
  ["PENSION", "Pension"],
  ["STATE_PENSION", "State Pension"],
  ["PRIVATE_PENSION", "Private Pension"],
  ["FOSTER_CARE", "Foster Care"],
  ["RENTAL", "Rental"],
  ["BURSARY", "Bursary"],
  ["TRUST", "Trust"],
  ["OTHER", "Other"],
]);

export type OUTGOINGS_CATEGORY =
  | "RENT_MORTGAGE"
  | "UTILITIES"
  | "COUNCIL_TAX"
  | "INSURANCE_PROPERTY"
  | "TV_PHONE_INTERNET"
  | "HOUSEHOLD"
  | "CHILDCARE"
  | "SCHOOL"
  | "INSURANCE_PERSONAL"
  | "FOOD_CLOTHING"
  | "MOTORING"
  | "MEDICINE"
  | "HOLIDAYS"
  | "CIGARETTES_ALCOHOL"
  | "HOBBIES"
  | "MOBILE_PHONE"
  | "SAVINGS"
  | "OTHER_EXPENSES"
  | "IGNORE";

export const OUTGOINGS_CATEGORY_NAME = new Map<OUTGOINGS_CATEGORY, string>([
  ["RENT_MORTGAGE", "Rent/Mortgage"],
  ["UTILITIES", "Utilities (Gas, Electric, Water)"],
  ["COUNCIL_TAX", "Council Tax"],
  ["INSURANCE_PROPERTY", "Insurance (Contents, Property, etc.)"],
  ["TV_PHONE_INTERNET", "Satellite TV, Landline, Internet"],
  ["HOUSEHOLD", "Household Goods & Services"],
  ["CHILDCARE", "Child Care"],
  ["SCHOOL", "School Fees"],
  ["INSURANCE_PERSONAL", "Insurance & Pension Policies"],
  ["FOOD_CLOTHING", "Food & Clothing"],
  ["MOTORING", "Motoring"],
  ["MEDICINE", "Medicines, Hairdressing, Other"],
  ["HOLIDAYS", "Holidays"],
  ["CIGARETTES_ALCOHOL", "Cigarettes & Alcohol"],
  ["HOBBIES", "Hobbies"],
  ["MOBILE_PHONE", "Mobile Phone"],
  ["SAVINGS", "Regular Savings"],
  ["OTHER_EXPENSES", "All Other Expenses"],
  // ["IGNORE", "IGNORE"],
]);

export type CREDIT_TYPE =
  | "CREDIT_CARD"
  | "STORE_CARD"
  | "PERSONAL_LOAN"
  | "STUDENT_LOAN"
  | "HIRE_LEASE";

export const CREDIT_TYPE_NAME = new Map<CREDIT_TYPE, string>([
  ["CREDIT_CARD", "Credit Card"],
  ["STORE_CARD", "Store Card"],
  ["PERSONAL_LOAN", "Personal Loan"],
  ["STUDENT_LOAN", "Student Loan"],
  ["HIRE_LEASE", "Hire Purchase or Lease Agreement"],
]);

export type LOAN_NAME = "MINE" | "MINE_OTHER_APPLICANTS";

export const LOAN_NAME_NAME = new Map<LOAN_NAME, string>([
  ["MINE", "Mine"],
  ["MINE_OTHER_APPLICANTS", "Mine and Someone Else"],
]);

export type REPAY_METHOD =
  | "SAVINGS"
  | "SALARY"
  | "BONUS_COMMISSION"
  | "GIFT"
  | "MORTGAGE_FUNDS"
  | "INHERITANCE"
  | "CLEARED_MONTHLY"
  | "OTHER";

export const REPAY_METHOD_NAME = new Map<REPAY_METHOD, string>([
  ["CLEARED_MONTHLY", "Cleared Monthly"],
  ["SAVINGS", "Savings"],
  ["SALARY", "Monthly Salary"],
  ["BONUS_COMMISSION", "Bonus / Commission"],
  ["GIFT", "Gift"],
  ["MORTGAGE_FUNDS", "Mortgage Funds"],
  ["INHERITANCE", "Inheritance"],
  ["OTHER", "Other"],
]);

export type ASSET_TYPE =
  | "PROPERTY"
  | "SAVINGS"
  | "PRIVATE_PENSION"
  | "STOCKS_SHARES"
  | "OTHER";

export const ASSET_TYPE_NAME = new Map<ASSET_TYPE, string>([
  ["PROPERTY", "Property"],
  ["SAVINGS", "Savings"],
  ["PRIVATE_PENSION", "Private Pension"],
  ["STOCKS_SHARES", "Stocks / Shares"],
  ["OTHER", "Other"],
]);

export type PROPERTY_PAYMENT_METHOD =
  | "INTEREST_ONLY"
  | "CAPITAL_REPAYMENT"
  | "PART_AND_PART";

export const PROPERTY_PAYMENT_METHOD_NAME = new Map<
  PROPERTY_PAYMENT_METHOD,
  string
>([
  ["INTEREST_ONLY", "Interest Only"],
  ["CAPITAL_REPAYMENT", "Capital Repayment"],
  ["PART_AND_PART", "Part and Part"],
]);

export type PROPERTY_ASSET_STATUS =
  | "MAIN_RESIDENCE"
  | "SECOND_HOME"
  | "DEPENDANT_RELATIVE_HOME"
  | "HOLIDAY_HOME"
  | "VACANT"
  | "OTHER";

export const PROPERTY_ASSET_STATUS_NAME = new Map<
  PROPERTY_ASSET_STATUS,
  string
>([
  ["MAIN_RESIDENCE", "Main Residence"],
  ["SECOND_HOME", "Second Home"],
  ["DEPENDANT_RELATIVE_HOME", "Dependant Relative's Home"],
  ["HOLIDAY_HOME", "Holiday Home"],
  ["VACANT", "Vacant"],
  ["OTHER", "Other"],
]);

export type PROPERTY_TYPE = "HOUSE" | "BUNGALOW" | "FLAT_MAISONETTE";

export const PROPERTY_TYPE_NAME = new Map<PROPERTY_TYPE, string>([
  ["HOUSE", "House"],
  ["BUNGALOW", "Bungalow"],
  ["FLAT_MAISONETTE", "Flat/Maisonette"],
]);

export type PROPERTY_FLAT_TYPE = "PURPOSE_BUILD" | "CONVERTED";

export const PROPERTY_FLAT_TYPE_NAME = new Map<PROPERTY_FLAT_TYPE, string>([
  ["PURPOSE_BUILD", "Purpose Build"],
  ["CONVERTED", "Converted"],
]);

export type PROPERTY_HOUSE_TYPE = "SEMI" | "TERRACED" | "DETACHED";

export const PROPERTY_HOUSE_TYPE_NAME = new Map<PROPERTY_HOUSE_TYPE, string>([
  ["SEMI", "Semi"],
  ["TERRACED", "Terraced"],
  ["DETACHED", "Detached"],
]);

export type PROPERTY_TENURE = "FREEHOLD" | "LEASEHOLD";

export const PROPERTY_TENURE_NAME = new Map<PROPERTY_TENURE, string>([
  ["FREEHOLD", "Freehold"],
  ["LEASEHOLD", "Leasehold"],
]);

export type PROPERTY_BEDROOMS =
  | "STUDIO"
  | "ONE"
  | "TWO"
  | "THREE"
  | "FOUR"
  | "FIVE"
  | "SIX_PLUS";

export const PROPERTY_BEDROOMS_NAME = new Map<PROPERTY_BEDROOMS, string>([
  ["STUDIO", "Studio"],
  ["ONE", "1"],
  ["TWO", "2"],
  ["THREE", "3"],
  ["FOUR", "4"],
  ["FIVE", "5"],
  ["SIX_PLUS", "6+"],
]);

export type PRODUCT_TYPE = "FIXED" | "DISCOUNT" | "TRACKER";

export const PRODUCT_TYPE_NAME = new Map<PRODUCT_TYPE, string>([
  ["FIXED", "Fixed"],
  ["DISCOUNT", "Discount"],
  ["TRACKER", "Tracker"],
]);

export type DEBIT_COLLECTION_DATE = "FIRST" | "LAST" | "OTHER";

export const DEBIT_COLLECTION_DATE_NAME = new Map<
  DEBIT_COLLECTION_DATE,
  string
>([
  ["FIRST", "First Day of Month"],
  ["LAST", "Last Day of Month"],
  ["OTHER", "Other"],
]);

export const REPAYMENT_PLAN_MAP = new Map<string, string>([
  ["savingsInvestment", "Savings plan or other investment"],
  ["propertySale", "Sale of property"],
  ["convertToLaterRepayment", "Convert to repayment at a later date"],
  ["anticipatedInheritance", "Anticipated inheritance"],
  ["irregularRepayments", "Making irregular repayments of capital"],
]);

export type DOCUMENT_TYPE =
  | "PAYSLIPS"
  | "P60"
  | "SALARY_BANK_STATEMENT"
  | "SA302"
  | "TAX_YEAR_OVERVIEW"
  | "ACCOUNTS"
  | "CURRENT_CONTRACT"
  | "PREVIOUS_CONTRACT"
  | "CV"
  | "ID"
  | "UTILITY_BILL"
  | "BUILDING_CONTENTS_POLICY"
  | "LIFE_INSURANCE_POLICY"
  | "MISCELLANEOUS"
  | "PROOF_OF_DEPOSIT";

export const DOCUMENT_TYPE_NAME = new Map<DOCUMENT_TYPE, string>([
  ["PAYSLIPS", "Latest 3 Months of Payslips"],
  ["P60", "Your Latest P60"],
  [
    "SALARY_BANK_STATEMENT",
    "Latest 3 Months of Bank Statements Showing Salary Payments and All Other Transactions",
  ],
  ["SA302", "3 Years SA302's"],
  ["TAX_YEAR_OVERVIEW", "Latest 3 Years Tax Year Overviews"],
  ["ACCOUNTS", "Latest 3 Years Accounts"],
  ["CURRENT_CONTRACT", "Current Contract"],
  ["PREVIOUS_CONTRACT", "Previous Contract"],
  ["CV", "Your CV"],
  ["ID", "Identification (Passport or Driving License)"],
  ["UTILITY_BILL", "Utility Bill Dated Within 3 Months"],
  ["BUILDING_CONTENTS_POLICY", "Current Buildings and Contents Policy"],
  [
    "LIFE_INSURANCE_POLICY",
    "Current Life Insurance, Critical Illness and Income Protection Policies",
  ],
  ["MISCELLANEOUS", "Miscellaneous"],
  ["PROOF_OF_DEPOSIT", "Proof of Deposit"],
]);

export type DEPOSIT_SOURCE_TYPE =
  | "BUILDER_GIFT"
  | "GIFT"
  | "LOAN"
  | "SAVINGS"
  | "PROPERTY_SALE"
  | "INHERITANCE"
  | "VENDOR_GIFTED"
  | "EQUITY"
  | "OTHER_PROPERTY_SALE"
  | "FAMILY_GIFT"
  | "OTHER";

export const DEPOSIT_SOURCE_TYPE_NAME = new Map<DEPOSIT_SOURCE_TYPE, string>([
  ["BUILDER_GIFT", "Builders Gift"],
  ["GIFT", "Gift"],
  ["LOAN", "Loan"],
  ["SAVINGS", "Savings"],
  ["PROPERTY_SALE", "Sale of Property"],
  ["INHERITANCE", "Inheritance"],
  ["VENDOR_GIFTED", "Vendor Gifted"],
  ["EQUITY", "Equity"],
  ["OTHER_PROPERTY_SALE", "Sale of Other Property"],
  ["FAMILY_GIFT", "Immediate Family Gift"],
  ["OTHER", "Other"],
]);

export type SMART_SEARCH_AML_RESULT =
  | "PASS"
  | "REFER"
  | "ERROR"
  | "NA"
  | "CONTINUE"
  | "STOP"
  | "NODECISION"
  | "ACCEPT";

export type REGION_TYPE =
  | "GREATER_LONDON"
  | "SOUTH_EAST"
  | "SOUTH_WEST"
  | "EAST_MIDLANDS"
  | "WEST_MIDLANDS"
  | "EAST_OF_ENGLAND"
  | "NORTH_EAST"
  | "NORTH_WEST"
  | "YORKSHIRE_AND_THE_HUMBER"
  | "NORTHERN_IRELAND"
  | "SCOTLAND"
  | "WALES";

export const REGION_TYPE_NAME = new Map<REGION_TYPE, string>([
  ["GREATER_LONDON", "Greater London"],
  ["SOUTH_EAST", "South East"],
  ["SOUTH_WEST", "South West"],
  ["EAST_MIDLANDS", "East Midlands"],
  ["WEST_MIDLANDS", "West Midlands"],
  ["EAST_OF_ENGLAND", "East of England"],
  ["NORTH_EAST", "North East"],
  ["NORTH_WEST", "North West"],
  ["YORKSHIRE_AND_THE_HUMBER", "Yorkshire and the Humber"],
  ["NORTHERN_IRELAND", "Northern Ireland"],
  ["SCOTLAND", "Scotland"],
  ["WALES", "Wales"],
]);
