import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "hfc-textarea-input",
  templateUrl: "./textarea-input.component.html",
  styleUrls: ["./textarea-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextareaInputComponent,
      multi: true,
    },
  ],
})
export class TextareaInputComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input()
  @HostBinding("class.readonly")
  public readonly: boolean;

  @HostBinding("class.focus")
  public focus = false;

  @Input()
  public rows = 3;

  @Input()
  public autoexpand = false;

  @Input()
  public editor = false;

  @Input() public isZoomContent = false;

  @ViewChild("textarea") public textarea: ElementRef;
  @ViewChild("editableContent") public editableContent: ElementRef;

  public value: string = "";

  private initialHeight: number = 0;
  private height: number = 0;

  private onChange: (value: string) => void;
  private onTouchedFn: () => void;

  constructor() {}

  ngAfterViewInit(): void {
    this.initialHeight = this.textarea?.nativeElement.offsetHeight || 0;

    // For Zoom AI content, checks if the value exists and the UI reflects it.
    // Fixes innerHTML issue and Angular change detection issues.
    if (this.isZoomContent && this.value && this.editableContent) {
      this.editableContent.nativeElement.innerHTML = this.value;
    }
    this.resize();
  }

  public onInput(event: Event): void {
    this.value = (event.target as HTMLElement).innerHTML;
    this.onModelChange();
  }

  public onModelChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
    this.resize();
  }

  public onBlur(): void {
    if (this.onTouchedFn) {
      this.onTouchedFn();
    }
  }

  public command(cmd: string): void {
    const selection = document.getSelection()?.toString();
    document.execCommand(cmd, false, selection);
  }

  private resize(): void {
    if (!this.textarea || !this.autoexpand) {
      return;
    }
    const target = this.textarea.nativeElement;
    const height = Math.max(
      this.initialHeight,
      this.height,
      target.scrollHeight
    );

    if (height !== this.height) {
      this.height = height;
      target.style.minHeight = `${this.height}px`;
    }
  }

  public writeValue(obj: any): void {
    this.value = obj;

    // Update the .innerHTML only if isZoomContent is true and editableContent is set
    this.updateZoomContent();

    // Trigger resize after ensuring DOM updates are complete
    requestAnimationFrame(() => this.resize());
  }

  private updateZoomContent(): void {
    if (!this.isZoomContent || !this.editableContent) return;

    const selection = window.getSelection();
    const range =
      selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    // Safely update the inner HTML
    const sanitizedValue = this.sanitize(this.value);
    this.editableContent.nativeElement.innerHTML = sanitizedValue;

    // Restore selection range
    if (selection && range) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  private sanitize(value: any): string {
    return value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
}
