import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

// export type ValueFinisherFn = (value: string) => string;

function capitalize(text: string): string {
  if (!text) {
    return text;
  }

  return text.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
}

@Component({
  selector: "hfc-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextInputComponent,
      multi: true,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor {
  public value: string = "";

  @Input()
  public type: string = "text";

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public maxlength: number = 255;

  @Input()
  public placeholder: string = "";

  @Input()
  public prefix: string;

  @Input()
  public sufix: string = "";

  @Input()
  public icon: string;

  @Input()
  public capitalize: boolean = false;

  @Input()
  public mask: string;

  @Input()
  public autocomplete: string;

  @Input()
  public disableDecimal?: boolean = false;

  @Input()
  @HostBinding("class.readonly")
  public readonly: boolean;

  public _currency = false;
  @Input()
  public set currency(value: boolean) {
    this._currency = value;

    if (value) {
      this.mask = "separator";
    }
  }

  public _integer = false;
  @Input()
  public set integer(value: boolean) {
    this._integer = value;

    if (value) {
      this.mask = "0*";
    }
  }

  @Input()
  public pattern: string;

  @HostBinding("class.focus")
  public focus = false;

  // @Input()
  // public valueFinisher: ValueFinisherFn;

  public onModelChange() {
    this.onChane &&
      this.onChane(this.capitalize ? capitalize(this.value) : this.value);
  }

  public onInputBlur() {
    this.onTouchedFn && this.onTouchedFn();
  }

  @HostListener("click")
  public setFocus() {
    this.el.nativeElement.querySelector("input").focus();
  }

  constructor(private el: ElementRef) {}

  private onChane: (value: string) => void;
  private onTouchedFn: () => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChane = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
