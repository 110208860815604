import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[hfcTwoDigitDecimalNumber]",
})
export class TwoDigitDecimalNumberDirective {
  constructor(private el: ElementRef) {}

  /**
   * HostListener fires on every input event.
   */
  @HostListener("input", ["$event"])
  onInputChange(event: Event): void {
    const inputEl = this.el.nativeElement as HTMLInputElement;
    let value: string = inputEl.value;

    // Trim the decimal portion to a max of 2 digits
    const parts = value.split(".");
    if (parts.length === 2 && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
      inputEl.value = parts.join(".");
    }
  }
}
