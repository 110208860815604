<div *ngIf="prefix" class="prefix">{{ prefix }}</div>

<!-- \\d* as pattern to trigger numeric keyboard on iphone -->

<div class="input-wrapper">
  <input
    *ngIf="mask && !disableDecimal"
    [type]="type"
    [attr.min]="min"
    [attr.max]="max"
    [attr.maxlength]="maxlength"
    class="input"
    [class.capitalize]="capitalize"
    [class.currency]="_currency"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange()"
    [mask]="mask"
    [thousandSeparator]="_currency ? ',' : null"
    [placeholder]="placeholder"
    [attr.autocomplete]="autocomplete"
    [attr.pattern]="_currency || _integer ? '\\d*' : pattern || undefined"
    [attr.readonly]="readonly || undefined"
    (focus)="focus = true"
    (focusout)="focus = false"
    (blur)="onInputBlur()"
  />
  <input
    *ngIf="disableDecimal"
    [type]="type"
    [attr.min]="min"
    [attr.max]="max"
    [attr.maxlength]="maxlength"
    class="input"
    [class.capitalize]="capitalize"
    [class.currency]="_currency"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange()"
    hfcTwoDigitDecimalNumber
    [thousandSeparator]="_currency ? ',' : null"
    [placeholder]="placeholder"
    [attr.autocomplete]="autocomplete"
    [attr.pattern]="_currency || _integer ? '\\d*' : pattern || undefined"
    [attr.readonly]="readonly || undefined"
    (focus)="focus = true"
    (focusout)="focus = false"
    (blur)="onInputBlur()"
  />

  <input
    *ngIf="!mask && !disableDecimal"
    [type]="type"
    [attr.min]="min"
    [attr.max]="max"
    [attr.maxlength]="maxlength"
    class="input"
    [class.capitalize]="capitalize"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange()"
    [placeholder]="placeholder"
    [attr.autocomplete]="autocomplete"
    [attr.pattern]="type === 'number' ? '\\d*' : pattern || undefined"
    [attr.readonly]="readonly || undefined"
    (focus)="focus = true"
    (focusout)="focus = false"
    (blur)="onInputBlur()"
  />
</div>

<div *ngIf="sufix" class="sufix">{{ sufix }}</div>

<i *ngIf="icon" class="material-icons icon">{{ icon }}</i>

<span class="material-icons error-icon">error</span>
